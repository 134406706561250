import {
  CartLine, CurrencyCode,
  GetCartQuery,
  LineFragmentFragment,
} from "../types/storefront.generated";
import {
  ClubLabel,
  ICartBundleItem,
  ICartGiftItem,
  ICartLineItem,
  ICheckout,
  ICustomerAddress,
  IFreeGiftTier,
  ILineItem,
  IShippingAddress,
} from "../types/types";
import { storefrontSdk } from "./sdk";
import { currencyToFloat } from "./currencyToFloat";
import config from "./config";
import { getShippingRatesForCheckout } from "./getShippingRatesForCart";
import {isPartnerClub, mapClubLabel} from "./mapClubLabel";
import Decimal from "decimal.js";

export default async function createCheckoutFromCart(
  cart: GetCartQuery["cart"],
) {
  if (!cart) {
    return Promise.reject("No cart found");
  }

  // @TODO Check that cart items are available for sale
  // line.merchandise.availableForSale must be true
  // Likely some more logic required here for preorder items
  // If not, should throw a custom error that can be
  // handled on both front and backend.
  // It should contain a list of *all* out of stock cart items

  let activeClub = mapClubLabel(cart.activeClub?.value ?? config.activeClub);
  let customerId = cart.buyerIdentity.customer?.id ?? null;
  if (cart.discountCodes.length > 0) {
    for (const code of cart.discountCodes) {
      if (config.partnerCodes.includes(code.code.toUpperCase())) {
        activeClub = "clubPT";
        customerId = customerId ?? 'partnercode';
        break;
      }
    }
  }

  const filteredLineItems = cart.lines.nodes
    .map((line) =>
      transformLineItem(
        line,
        customerId,
        activeClub,
      ),
    )
    .filter((item): item is ILineItem => item !== null);

  const availableItems = filteredLineItems.filter(
    (item) => item.availableForSale,
  );

  const unavailableItems = filteredLineItems.filter(
    (item) => !item.availableForSale,
  );

  const checkout: ICheckout = {
    cartId: cart.id,
    customer: null,
    email: null,
    phone: null,
    currencyCode: config.currencyCode as CurrencyCode,
    lineItems: availableItems,
    outOfStockLineItems: unavailableItems,
    bundleItems: [],
    giftItems: [],
    freeGiftTier: null,
    availableShippingMethods: [],
    shippingMethod: null,
    shippingAddress: null,
    discountCode: null,
    giftCard: null,
    paymentMethod: null,
    completed: false,
    emailMarketingOptIn: true,
  };
  const storefront = storefrontSdk();

  if (cart?.giftsWithPurchase?.value) {
    const giftsWithPurchase: ICartGiftItem[] = JSON.parse(
      cart.giftsWithPurchase.value,
    );
    const giftQuantities: { [variantId: string]: number } = {};

    // Aggregate gift quantities by variant ID
    giftsWithPurchase.forEach((giftItem) => {
      const variantId = giftItem.parentProductId;
      if (giftQuantities[variantId]) {
        giftQuantities[variantId]++;
      } else {
        giftQuantities[variantId] = 1;
      }
    });

    // Create a map of line item quantities
    const lineItemsMap: { [variantId: string]: number } = {};
    checkout.lineItems.forEach((lineItem) => {
      lineItemsMap[lineItem.variantId] = lineItem.quantity;
    });

    // Add valid gifts to checkout.giftItems, respecting the line item quantities
    const addedGiftsCount: { [variantId: string]: number } = {};
    giftsWithPurchase.forEach((giftItem) => {
      const variantId = giftItem.parentProductId;
      const lineItemQuantity = lineItemsMap[variantId] || 0;
      const currentAddedCount = addedGiftsCount[variantId] || 0;

      if (currentAddedCount < lineItemQuantity) {
        checkout.giftItems.push({
          id: variantId,
          parentVariantId: giftItem.parentProductId,
          productTitle: giftItem.variant.product.title,
          variantTitle: giftItem.variant.title,
          imageUrl: giftItem.variant.image.url ?? "",
          quantity: 1,
          selectedOptions: giftItem.variant.selectedOptions,
        });
        addedGiftsCount[variantId] = currentAddedCount + 1;
      }
    });
  }

  if (cart?.bundles?.value) {
    const cartBundles: ICartBundleItem[] = JSON.parse(cart.bundles.value);
    if (cartBundles) {
      for (const bundleItem of cartBundles) {
        const bundleData = await storefront.getBundle({
          id: bundleItem.bundle.id,
        });
        if (!bundleData.metaobject) {
          continue;
        }
        const bundlePrice = JSON.parse(
          bundleData.metaobject.price?.value ?? "",
        );
        checkout.bundleItems.push({
          id: bundleItem.bundle.id,
          title: bundleData.metaobject.title?.value ?? "",
          // @ts-ignore
          imageUrl: bundleData.metaobject.image?.reference?.image.src ?? "",
          quantity: parseInt(bundleData.metaobject.quantity?.value ?? "1"),
          price: bundlePrice.amount,
          comparePrice: bundleItem.selectedVariants
            .reduce(
              (accumulator, variant) => {
                const price = variant.compareAtPrice?.amount ?? variant.price.amount;
                return accumulator + parseFloat(price);
              }, 0,
            )
            .toFixed(2),
          selectedVariants: bundleItem.selectedVariants,
        });
      }
    }
  }

  if (cart?.freeGiftTier?.value) {
    const freeGiftTier: IFreeGiftTier = JSON.parse(cart?.freeGiftTier?.value);
    if (freeGiftTier) {
      checkout.freeGiftTier = freeGiftTier;
    }
  }

  if (cart?.completed?.value === "true") {
    checkout.completed = true;
  }

  if (cart.buyerIdentity.customer) {
    checkout.customer = {
      customerId: cart.buyerIdentity.customer.id ?? null,
      customerToken: null,
      addresses: cart.buyerIdentity.customer.addresses.nodes.map(
        transformCustomerAddress,
      ),
      email: cart.buyerIdentity.customer.email ?? null,
      phone: cart.buyerIdentity.customer.phone ?? null,
      preferredAddress: null,
    };
  }

  checkout.email =
    cart.buyerIdentity?.email ?? cart.buyerIdentity?.customer?.email ?? null;
  checkout.phone =
    cart.buyerIdentity?.phone ?? cart.buyerIdentity?.customer?.phone ?? null;

  if (cart.buyerIdentity.deliveryAddressPreferences.length > 0) {
    const preferredAddress = transformShippingAddress(
      cart.buyerIdentity.deliveryAddressPreferences[0],
    );
    checkout.shippingAddress = preferredAddress;
    const customerAddress = getCustomerAddress(
      preferredAddress,
      checkout.customer?.addresses ?? [],
    );
    if (checkout.customer && customerAddress) {
      checkout.customer.preferredAddress = customerAddress;
    }
  } else if (checkout.customer && cart.buyerIdentity.customer?.defaultAddress) {
    checkout.customer.preferredAddress = {
      ...transformShippingAddress(cart.buyerIdentity.customer.defaultAddress),
      id: cart.buyerIdentity.customer.defaultAddress.id,
    };
  }

  if (checkout.shippingAddress || checkout.customer?.preferredAddress) {
    const shippingRates = await getShippingRatesForCheckout(checkout);
    if (shippingRates) {
      checkout.availableShippingMethods = shippingRates.map((shippingRate) => {
        return {
          groupId: "",
          handle: shippingRate.handle,
          title: shippingRate.title,
          price: shippingRate.price.amount,
        };
      });
      checkout.shippingMethod = checkout.availableShippingMethods[0];
    } else {
      console.error("No shipping rates found for checkout", {
        address: checkout.shippingAddress,
        cartId: checkout.cartId,
      });
    }
  }

  // if (cart.deliveryGroups?.nodes) {
  //   const deliveryGroup = cart.deliveryGroups.nodes[0];
  //   if (deliveryGroup) {
  //     checkout.availableShippingMethods = deliveryGroup.deliveryOptions.map(
  //       (deliveryOption) => transformDeliveryGroup(deliveryOption, deliveryGroup.id)
  //     );
  //     if (deliveryGroup.selectedDeliveryOption) {
  //       checkout.shippingMethod = transformDeliveryGroup(deliveryGroup.selectedDeliveryOption, deliveryGroup.id);
  //     }
  //   }
  // }

  const appliedDiscountCode = cart.discountCodes.find(
    (code) => code.applicable || config.partnerCodes.includes(code.code.toUpperCase()),
  );
  if (appliedDiscountCode) {
    checkout.discountCode = appliedDiscountCode.code;
  }

  const appliedgiftCard = cart.giftCard;
  if (appliedgiftCard?.value) {
    checkout.giftCard = appliedgiftCard.value;
  }

  return checkout;
}

function isCartLine(line: any): line is CartLine {
  return line && line.merchandise;
}

function transformLineItem(
  line: ICartLineItem | { __typename?: "ComponentizableCartLine" },
  customerId: string | null,
  activeClub: ClubLabel,
): ILineItem | null {
  if (!isCartLine(line)) {
    // Handle other types or return null
    console.warn("Skipping non-CartLine item:", line);
    return null;
  }

  return {
    variantId: line.merchandise.id,
    productId: line.merchandise.product.id,
    productTitle: line.merchandise.product.title,
    variantTitle: line.merchandise.title,
    url: `${config.storeUrl}/products/${line.merchandise.product.handle}?variant=${line.merchandise.id}`,
    sku: line.merchandise.sku ?? "",
    image: {
      url: line.merchandise.image?.url,
      altText: line.merchandise.image?.altText ?? null,
    },
    selectedOptions: line.merchandise.selectedOptions,
    quantity: line.quantity,
    price: calculatePrice(line, customerId, activeClub),
    comparePrice: calculateComparePrice(line, customerId),
    requiresShipping: line.merchandise.requiresShipping,
    availableForSale: line.merchandise.availableForSale,
  };
}

function transformCustomerAddress(address: {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  provinceCode?: string | null;
  province?: string | null;
  country?: string | null;
  zip?: string | null;
  phone?: string | null;
}): ICustomerAddress {
  return {
    id: address.id,
    firstName: address.firstName ?? "",
    lastName: address.lastName ?? "",
    address1: address.address1 ?? "",
    address2: address.address2 ?? null,
    city: address.city ?? "",
    province: address.province ?? "",
    provinceCode: address.provinceCode ?? "",
    country: address.country ?? "",
    zip: address.zip ?? "",
    phone: address.phone ?? null,
  };
}

function transformShippingAddress(address: {
  firstName?: string | null;
  lastName?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  provinceCode?: string | null;
  province?: string | null;
  country?: string | null;
  zip?: string | null;
  phone?: string | null;
}): IShippingAddress {
  return {
    firstName: address.firstName ?? "",
    lastName: address.lastName ?? "",
    address1: address.address1 ?? "",
    address2: address.address2 ?? null,
    city: address.city ?? "",
    province: address.province ?? "",
    provinceCode: address.provinceCode ?? "",
    country: address.country ?? "",
    zip: address.zip ?? "",
    phone: address.phone ?? null,
  };
}

function getCustomerAddress(
  address: IShippingAddress,
  addresses: ICustomerAddress[],
) {
  for (const customerAddress of addresses) {
    if (
      customerAddress.firstName === address.firstName &&
      customerAddress.lastName === address.lastName &&
      customerAddress.address1 === address.address1 &&
      customerAddress.address2 === address.address2 &&
      customerAddress.city === address.city &&
      customerAddress.country === address.country &&
      customerAddress.zip === address.zip
    ) {
      return customerAddress;
    }
  }
  return null;
}

// function transformDeliveryGroup(deliveryOption: ICartDeliveryGroup, groupId: string) : IShippingMethod {
//   return {
//     groupId,
//     handle: deliveryOption.handle,
//     title: deliveryOption.title ?? '',
//     price: deliveryOption.estimatedCost.amount,
//   }
// }

function calculatePrice(
  line: LineFragmentFragment,
  customerId: string | null,
  activeClub: ClubLabel,
) {
  const lineDiscount =
    line.discountAllocations?.[0]?.discountedAmount?.amount ?? null;
  if (lineDiscount) {
    const discountAmount = new Decimal(lineDiscount).div(line.quantity);
    return new Decimal(line.merchandise.price.amount)
      .minus(discountAmount)
      .toFixed(2);
  }
  if (line.merchandise.compareAtPrice?.amount && line.merchandise.price.amount !== line.merchandise.compareAtPrice?.amount) {
    return line.merchandise.price.amount;
  }
  const clubEnabled =
    // @ts-ignore @TODO fix these types
    line.merchandise.product[`${activeClub}Enabled`]?.value === "true" || isPartnerClub(activeClub);
  const clubPrice = clubEnabled
    ? line.merchandise[activeClub] || line.merchandise.product[activeClub]
    : null;
  if (customerId && clubEnabled && clubPrice) {
    return currencyToFloat(clubPrice.value);
  }
  return line.merchandise.price.amount;
}

function calculateComparePrice(
  line: LineFragmentFragment,
  customerId: string | null,
) {
  if (
    customerId &&
    (line.merchandise.clubAU || line.merchandise.product.clubAU)
  ) {
    return (
      line.merchandise.compareAtPrice?.amount ?? line.merchandise.price.amount
    );
  }
  if (
    line.merchandise.compareAtPrice?.amount &&
    line.merchandise.compareAtPrice?.amount !== line.merchandise.price.amount
  ) {
    return line.merchandise.compareAtPrice.amount;
  }
  return null;
}
