import {IAnalyticsProvider} from './trackingService';
import config from "../utils/config";
import {ICheckout, ICheckoutTotals} from "../types/types";

type WindowWithKlaviyo = Window & {
  klaviyo?: [string, { [key: string]: any } | string, { [key: string]: any }][] & {
    identify: (arg: any) => Promise<void>;
  };
};

declare const window: WindowWithKlaviyo;
class KlaviyoProvider implements IAnalyticsProvider {

  eventMap: Record<string, (data: any) => Promise<void>> = {
    'begin_checkout': this.beginCheckout
  }

  sentCheckoutStarted: boolean = false;

  async trackEvent(event: string, data: Record<string, any>): Promise<void> {
    if (this.eventMap[event]) {
      await this.eventMap[event](data);
      return;
    }
  }

  async beginCheckout(data: { checkout: ICheckout, totals: ICheckoutTotals }) {
    if (!window.klaviyo || this.sentCheckoutStarted || !data.checkout.email) {
      return;
    }
    if (config.environment !== 'production') {
      console.log('Sending checkout started event to Klaviyo');
      this.sentCheckoutStarted = true;
      return;
    }
    return window.klaviyo.identify({
      $email: data.checkout.email
    }).then(() => {
      window.klaviyo?.push(['track', 'Started Checkout', {
        '$event_id': data.checkout.cartId,
        '$value': data.totals.total,
        '$currency': data.totals.currencyCode,
        "CheckoutURL": `${config.checkoutUrl}/checkout/${data.checkout.cartId.replace('gid://shopify/Cart/', '')}`,
        'Items': data.checkout.lineItems,
      }]);
      this.sentCheckoutStarted = true;
    }).catch(() => {
      console.error('Failed to identify user');
    });
  }
}
export default KlaviyoProvider;
