type Region = {
  postcodePrefix: string;
  region: string;
  code: string;
};
interface PostcodeRange {
  start: number;
  end: number;
}

interface RegionPostcodes {
  [region: string]: PostcodeRange[];
}

const postcodeRegions: RegionPostcodes = {
  "New South Wales": [
    { start: 1000, end: 1999 },
    { start: 2000, end: 2599 },
    { start: 2619, end: 2899 },
    { start: 2921, end: 2999 }
  ],
  "Australian Capital Territory": [
    { start: 200, end: 299 },
    { start: 2600, end: 2618 },
    { start: 2900, end: 2920 }
  ],
  "Victoria": [
    { start: 3000, end: 3999 },
    { start: 8000, end: 8999 }
  ],
  "Queensland": [
    { start: 4000, end: 4999 },
    { start: 9000, end: 9999 }
  ],
  "South Australia": [
    { start: 5000, end: 5799 },
    { start: 5800, end: 5999 }
  ],
  "Western Australia": [
    { start: 6000, end: 6797 },
    { start: 6800, end: 6999 }
  ],
  "Tasmania": [
    { start: 7000, end: 7799 },
    { start: 7800, end: 7999 }
  ],
  "Northern Territory": [
    { start: 800, end: 899 },
    { start: 900, end: 999 }
  ]
};

export function postcodeToRegion(postcode: string): Region {
  if (postcode.length !== 4 || parseInt(postcode) <= 0) {
    throw new Error('Postcode must have exactly four characters.');
  }
  for (const region in postcodeRegions) {
    const ranges = postcodeRegions[region];
    for (const range of ranges) {
      if (parseInt(postcode) >= range.start && parseInt(postcode) <= range.end) {
        return {
          postcodePrefix: postcode,
          region,
          code: region,
        };
      }
    }
  }
  return {
    postcodePrefix: '2020',
    region: 'New South Wales',
    code: 'NSW',
  };
}
