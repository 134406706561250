import React from "react";
import { IFreeGiftTier } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";
import formatPrice from "../utils/formatPrice";

type FreeGiftProps = {
  freeGift: IFreeGiftTier;
  currencyCode: CurrencyCode;
};
const FreeGift = ({ freeGift, currencyCode }: FreeGiftProps) => (
  <li key={freeGift.id} className="flex items-start space-x-4 py-6">
    <div className={`relative`}>
      <img
        src={freeGift?.giftProduct.featuredImage.url ?? ""}
        alt={freeGift?.giftProduct.featuredImage.altText ?? ""}
        className="w-20 flex-none rounded-md object-cover object-center"
      />
    </div>

    <div className="w-20 flex-auto space-y-1">
      <>
        <h2>
          {freeGift?.giftProduct.title}
        </h2>
        <h3
          className={`font-light text-gray-500`}
        >
          {freeGift?.giftVariant.title}
        </h3>
      </>
    </div>
    <p className="flex-none text-base font-medium line-through">
      {formatPrice(freeGift?.giftVariant.price.amount, currencyCode)}
    </p>
    <p className="flex-none text-base font-medium text-gray-900">
      FREE
    </p>
  </li>
);
export default FreeGift;
