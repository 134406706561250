import React from 'react';
import {ShippingRegion} from "../types/types";

interface RegionDropdownProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  regions: ShippingRegion[];
  error?: string;
}

const RegionDropdown: React.FC<RegionDropdownProps> = ({ value, onChange, regions, error }) => (
  <div className="mt-2">
    <label htmlFor="province" className="sr-only block text-sm font-medium text-gray-700">
      Region
    </label>
    <select
      id="province"
      name="province"
      autoComplete="address-level1"
      className="invalid:text-gray-400 relative block p-3 rounded-full w-full border-0 bg-transparent text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-4 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
      value={value}
      onChange={onChange}
    >
      <option disabled value="">Region</option>
      {regions.map((region) => (
        <option key={region.code} value={region.code}>
          {region.label}
        </option>
      ))}
    </select>
    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
  </div>
);

export default RegionDropdown;
